"use client";

import Link from "next/link";
import BlackholeIcon from "./blackhole-icon";
import { usePathname } from "next/navigation";
import { SUPPORTED_CHAIN_IDS } from "src/config";
import { motion } from "framer-motion";
import { UserProfile } from "src/lib/wagmi/components/user-profile";
import { NetworkCheck } from "src/lib/wagmi/components/network-check";
import { useAccount, useSwitchChain } from "wagmi";
import { Chain, ChainId, chainName } from "sushi/chain";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Button,
} from "@sushiswap/ui";
import { useEffect, useState } from "react";

export default function EvmNavbar() {
  const currentPath = usePathname();
  const { chain, isConnected } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const targetChainId = SUPPORTED_CHAIN_IDS[0];
  const [showNetworkAlert, setShowNetworkAlert] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNetworkAlert(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const renderNetworkAlert = () => {
    if (showNetworkAlert && chain?.id !== targetChainId && isConnected) {
      return (
        <HoverCard openDelay={0} closeDelay={0}>
          <div className="bg-[#00FFFF] flex items-center  justify-center z-50 text-black p-2 w-full font-bold">
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1"
            >
              <path
                d="M16.7973 13.4997L10.7973 2.99968C10.6665 2.76883 10.4768 2.57682 10.2475 2.44323C10.0183 2.30964 9.75767 2.23926 9.49232 2.23926C9.22698 2.23926 8.96639 2.30964 8.73713 2.44323C8.50787 2.57682 8.31815 2.76883 8.18733 2.99968L2.18733 13.4997C2.05509 13.7287 1.98575 13.9886 1.98633 14.2531C1.98692 14.5175 2.05741 14.7771 2.19066 15.0055C2.32391 15.234 2.51519 15.4231 2.7451 15.5538C2.97501 15.6845 3.23538 15.7521 3.49983 15.7497H15.4998C15.763 15.7494 16.0215 15.6799 16.2493 15.5481C16.4771 15.4164 16.6662 15.227 16.7977 14.999C16.9292 14.771 16.9984 14.5125 16.9983 14.2493C16.9982 13.9861 16.9289 13.7276 16.7973 13.4997Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.5 6.75V9.75"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.5 12.7498H9.51"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Wrong network
            <button
              onClick={async () =>
                await switchChainAsync?.({ chainId: targetChainId })
              }
              className="underline ml-4 text-[#212121] hover:opacity-80"
            >
              Switch network
            </button>
          </div>
        </HoverCard>
      );
    }
    return <div className="relative"></div>;
  };

  return (
    <div className="z-20">
      <nav className="flex h-[70px] !bg-black z-20 items-center border border-b-white/20 border-t-0 border-x-0 px-4">
        <div className="flex-1 flex items-center">
          <Link href="/">
            <BlackholeIcon />
          </Link>
        </div>
        <div className="flex-1 flex gap-4 items-center justify-center">
          <div className="relative">
            <Link
              className={`text-xs px-2 py-4 ${
                currentPath === "/swap"
                  ? "text-bhCyan font-bold"
                  : "text-white/40 font-medium"
              }`}
              href="/swap"
            >
              SWAP
            </Link>
            {currentPath === "/swap" && (
              <motion.div
                className="absolute -bottom-[25px] left-0 right-0 h-[3px] bg-bhCyan"
                layoutId="underline"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.3 }}
              />
            )}
          </div>

          <div className="relative">
            <Link
              className={`text-xs px-2 py-4 ${
                currentPath === "/pool/my-positions"
                  ? "text-bhCyan font-bold"
                  : "text-white/40 font-medium"
              }`}
              href="/pool/my-positions"
            >
              POOLS
            </Link>
            {currentPath === "/pool/my-positions" && (
              <motion.div
                className="absolute -bottom-[25px] left-0 right-0 h-[3px] bg-bhCyan"
                layoutId="underline"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.3 }}
              />
            )}
          </div>

          <div className="relative">
            <Link
              className={`text-xs px-2 py-4 ${
                currentPath === "/quests"
                  ? "text-bhCyan font-bold"
                  : "text-white/40 font-medium"
              }`}
              href="/quests"
            >
              QUESTS
            </Link>
            {currentPath === "/quests" && (
              <motion.div
                className="absolute -bottom-[25px] left-0 right-0 h-[3px] bg-bhCyan"
                layoutId="underline"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.3 }}
              />
            )}
          </div>
        </div>
        <div className="flex-1 flex items-center justify-end">
          <UserProfile networks={SUPPORTED_CHAIN_IDS} />
        </div>
      </nav>
      {renderNetworkAlert()}
    </div>
  );
}
